import React from "react";
import "./Irctc.css";
import imgg from "../assets/irctcf.png";
import yesvant from "../assets/images/yesvant.png";
import yelahanka from "../assets/images/yelanka.png";

const Irctc = () => {
  return (
    <>
      <div className="background-container"></div>
      <div className="ss-title">
        <h1>IRCTC Food</h1>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="irctcimage">
        <img src={imgg} alt="IRCTC Food" />
      </div>
      <br />
      <br />

      <div className="ibuttons">
        <button>
          <a
            href="/Menu"
            style={{
              textDecoration: "none",
              color: "white",
              fontSize: "30px",
              textAlign: "center",
              display: "block",
              padding: "10px 20px",
              borderRadius: "5px",
              backgroundColor: "#ff5722",
            }}
          >
            Menu
          </a>
        </button>
      </div>

      <br />
      <br />
      <div className="ibuttons">
        <button>
          <a
            href=""
            style={{
              textDecoration: "none",
              color: "white",
              fontSize: "30px",
              textAlign: "center",
              display: "block",
              padding: "10px 20px",
              borderRadius: "5px",
              backgroundColor: "#ff5722",
            }}
          >
            Order Now
          </a>
        </button>
      </div>

      <br />
      <br />
      <div
        className="description12"
        style={{
          padding: "20px",
          backgroundColor: "#f9f9f9",
          borderRadius: "10px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          maxWidth: "800px",
          margin: "0 auto",
        }}
      >
        <h1
          style={{
            fontWeight: "bold",
            fontSize: "22px",
            color: "#333",
            marginBottom: "12px",
          }}
        >
          We Take Orders only for{" "}
          <span style={{ color: "#ff5722" }}>Yeshwanthpur Junction</span> And{" "}
          <span style={{ color: "#ff5722" }}>Yelahanka Station</span> Railway Station
        </h1>
        <br />
        <br />
        <h1 style={{ fontSize: "14px", lineHeight: "1.6", color: "#555" }}>
          At Baramati Foods, we bring you a world of flavors, crafted with care
          and passion. Explore our diverse range of delectable dishes that
          promise to tantalize your taste buds. From traditional favorites to
          innovative creations, we have something for every palate. Our
          commitment to quality shines through in every bite, as we source the
          finest ingredients and prioritize freshness and authenticity. Whether
          you're craving a comforting meal or seeking a new culinary adventure,
          Baramati Foods is your ultimate destination. Experience the essence of
          good food, shared moments, and memorable flavors with Baramati Foods.
          Order now and embark on a journey of gastronomic delight!
        </h1>
      </div>

      {/* Images of Yesvantpur and Yelahanka */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <img
          src={yesvant}
          alt="Yesvantpur"
          style={{ width: "500px", height: "400px", margin: "10px" }}
        />
        <img
          src={yelahanka}
          alt="Yelahanka"
          style={{ width: "500px", height: "400px", margin: "10px" }}
        />
      </div>
    </>
  );
};

export default Irctc;
