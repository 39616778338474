import React from "react";
import Home from "./pages/Home/Home";
import Header from "./components/Header/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Contact from "./components/contact/Contact";
import About from "./components/About/About";
import Menu from "./components/Menu/Menu";
import Services from "./components/Services/Services";
import { Modal } from "./components/Modals/Modal";
import Irctc from "./components/Irctc";
import Catering from "./components/catering/Catering";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/About" element={<About />} />
          <Route path="/Menu" element={<Menu />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Irctc" element={<Irctc />} />
          <Route path="/catering" element={<Catering />} />

          <Route path="/modal" element={<Modal />} />
        </Routes>
        <br />
        <br />
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
