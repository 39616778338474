import React, { useState } from 'react';
import chaats from "../../assets/images/chaats.jpg"; 
import samosa from "../../assets/images/samosa.jpg"; 
import puri from "../../assets/images/puri.jpg"; 
import north1 from "../../assets/images/north1.jpg"; 
import north2 from "../../assets/images/north2.jpg";
import sweets1 from "../../assets/images/7.jpg"; 
import sweets2 from "../../assets/images/10.jpg";
import sweets3 from "../../assets/images/14.jpg";
import './Menu.css';


const TabMenu = () => {
  const [activeTab, setActiveTab] = useState('veg');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
  
    <div>

   
   <div class="background-container"></div>
  

        <div className='menu-title'>
            <h1>Our Menu</h1>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className='m-all'>
      <span className='m-row1'>
       

        <div
          className={`m-tab ${activeTab === 'nonVeg' ? 'active' : ''}`}
          onClick={() => handleTabClick('nonVeg')}
        >
          Lunch/Dinner
        </div>
        
        <div
          className={`m-tab ${activeTab === 'Breakfast' ? 'active' : ''}`}
          onClick={() => handleTabClick('Breakfast')}
        >
          Breakfast
        </div>
        <div
          className={`m-tab ${activeTab === 'veg' ? 'active' : ''}`}
          onClick={() => handleTabClick('veg')}
        >
          Sweets
        </div>
      </span>

      <span className='m-row2'>
       

        <div
          className={`m-tab ${activeTab === 'Starters' ? 'active' : ''}`}
          onClick={() => handleTabClick('Starters')}
        >
          Chats
        </div>
      </span>
    </div>
      <br/>

      <div className="m-tabcontent">
        {activeTab === 'veg' && (
            <div className='m-flex'>
            <div className='m-img'>
          <img src={sweets1} alt="Veg Image" />
          <br/>
          <img src={sweets2} alt="Non-Veg Image" />
          <br/>
          <img src={sweets3} alt="Non-Veg Image" />
          </div>

          <div className='m-text'>

            <ul>
            <li>Barabat special </li>
            <li>Rasgulla</li>
            <li>Rasmalai</li>
            <li>Raj Bhog</li>
            <li>Chhena Gaja</li>
            <li>Chhena poda</li>
            <li>Chhena Jhili</li>
          <li>Kalakand</li>
          <li>Besan Ladoo</li>
          <li>Gulab Jamun</li>
          <li>kala Jamun</li>
          <li>Malpua</li>
          <li>Bundi</li>
          <li>Ladoo</li>
          <li>Khaja</li>
          <li>Sweet Samosa</li>
          <li>Khasta Gaja</li>
          <li>Labanga Lata</li>
          <li>Chandrakala</li>
          <li>Coconut Ladoo</li>
         
          
            
            </ul>

            </div>
            </div>
        )}
        {activeTab === 'nonVeg' && (
          <div className='m-flex'>
          <div className='m-img'>
        <img src={north1} alt="Veg Image" />
        <br/>
        <img src={north2} alt="Non-Veg Image" />
        </div>

        <div className='m-text'>

          <ul>
          <li>Paneer Tikka</li>
            <li>Palak Paneer</li>
            <li>paneer biryani</li>
            <li>dal makhani</li>
            <li>Paneer kolapuri</li>
            <li>Aloo Gobi</li>
            <li>Baingan Bharta</li>
            <li>Roti</li>
            <li>Mini Thali</li>
          
          
          </ul>

          </div>
          </div>
        )}
{activeTab === 'Breakfast' && (
<div className='m-flex'>
          <div className='m-img'>
        <img src={samosa} alt="Veg Image" />
        <br/>
        <img src={puri} alt="Non-Veg Image" />
        </div>

        <div className='m-text'>

          <ul>
          <li>Chole Bhatura</li>
           
            <li>masala Dosa</li>
            <li>Bara Ghugni</li>
            <li>Dahi Bara Aloo Dum</li>
            <li>Samosa</li>
            <li>Aloo Chop</li>
          <li>Kachori</li>
          <li>Vada</li>
          <li>Veg. Chop</li>
          <li>Onion Pakoda</li>
          <li>Nimiki</li>
          
          </ul>

          </div>
          </div>
)}


{activeTab === 'MainCourse' && (
<div className='m-flex'>
          <div className='m-img'>
        <img src="http://asiangrandeur.in/img/veg-kadai.jpg" alt="Veg Image" />
        <br/>
        <img src="http://asiangrandeur.in/img/menu%20full.jpeg" alt="Non-Veg Image" />
        <br/>
        <img src="http://asiangrandeur.in/img/Vegetarian_Sichuan_Fried_Rice-1.jpg" alt="Veg Image" />
        <br/>
        <img src="http://asiangrandeur.in/img/chicken-korma.jpg" alt="Veg Image" />
        <br/>
        <img src="http://asiangrandeur.in/img/1-6.jpg" alt="Veg Image" />
        </div>

        <div className='m-text'>

          <ul>
          <li>Dal Fry</li>
            <li>Dal Tadka</li>
            <li>Dal Makhani</li>
            <li>Mix Veg Kurma</li>
            <li>Sabji Nizami Handi</li>
            <li>Veg makhanwala</li>
            <li>Mixed Veg Kadai</li>
          <li>Capsicum masala</li>
          <li>Palak Paneer</li>
          <li>Paneer Masala</li>
          <li>Chicken Kolhapuri</li>
          <li>Buna Murgh</li>
          <li>Chicken Patiala</li>
            <li>Murgh makhanwala</li>
            <li>Mughalai Chicken</li>
            <li>Chicken kalimirch</li>
            <li>Egg Masala</li>
          <li>Chicken Tikka Masala</li>
          <li>Garlic Chicken</li>
          <li>Egg Pepper Fryt</li>
          <li>
            Mutton Roganjosh
          </li>
          <li>Mutton Kolhapuri</li>

          <li>Mushroom Mutton Masala</li>
          <li>Mushroom Paneer Masala</li>
            <li>Paneer Pulao</li>
            <li>Kaju Pulao</li>
            <li>Chilly Garlic Chicken Fried Rice</li>
            <li>Schewzan Veg Fried Rice</li>
          <li>Schewzan Chicken Fried Rice</li>
          <li>Chicken Hyderabadi</li>
          <li>Chilly Paneer Tomato Capsicum Gravy</li>
          
          
          </ul>

          </div>
          </div>
)}

{activeTab === 'Starters' && (
          <div className='m-flex'>
          <div className='m-img'>
        <img src={chaats} alt="Veg Image" />
        <br/>
       
        
       
        </div>

        <div className='m-text'>

          <ul>
          <li>Aloo Chaat</li>
          <br/>
            <li>Samosa Chaat</li>
            <br/>
            <li>Kachori Chaat</li>
            <br/>
            <li>Papdi Chaat</li>
            <br/>
            <li>Masala Puri</li>
            <br/>
            <li>Gobi Manchurian</li>
            <br/>
           
          
          </ul>

          </div>
          </div>
        )}

{activeTab === 'Biryanis' && (
          <div className='m-flex'>
          <div className='m-img'>
        <img src="http://asiangrandeur.in/img/lastee3.jpeg" alt="Veg Image" />
        <br/>
        <img src="http://asiangrandeur.in/img/Egg%20Biryani.jpg" alt="Non-Veg Image" />
        
        
       
        </div>

        <div className='m-text'>

          <ul>
          
            <li>Veg Biryani</li>
            <li>Chicken Tikka Biryani</li>
            <li>Mutton Fry Biryani</li>
            <li>Paneer Biryani</li>
            <li>Paneer Tikka Biryani</li>
            <li>Mushroom Biryani</li>
          <li>Egg Biryani</li>
          <li>Veg Pulao</li>
          <li>Scezwan Chicken Noodles</li>
          <li>Chicken hakka Noodles</li>
          <li>Chicken Garlic noodles</li>

          
          
          </ul>

          </div>
          </div>
        )}


      </div>

    
    </div>
  );
};

export default TabMenu;
