import React ,{useEffect} from 'react'
import './About.css'

import image1 from "../../../src/assets/images/21.jpg"


const About = () => {

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
    <div>

    <div class="background-container"></div>
    <div className='ss-title'>
       <h1>About Us</h1>
       </div>


<br/>
<br/>
    <div className='a-container'>
     <div className='a-left'>
        <img style={{height:'450px',width:'550px',borderRadius:'10px'}}  src={image1} />
         {/* <img style={{marginLeft:'300px',marginTop:'-480px',width:'300px',height:'360px',alignSelf:"center",display:'flex'}} src={image1}/> */}
     </div>
     <div className='a-right'>
         <h1>Our Story</h1>
         <h5>
         Barabati Sweets, a confectionery born out of a passion for crafting sweet experiences, began its journey with a vision to redefine dessert indulgence. Established with a commitment to perfection, our story unfolds in the heart of the city, where the aroma of freshly baked treats fills the air, creating a haven for dessert enthusiasts.

         Founded by a team of dedicated pastry chefs and sweet enthusiasts, Barabati Sweets takes pride in its rich heritage of creating authentic and mouthwatering delicacies. Our recipes, passed down through generations, encapsulate the essence of traditional flavors while embracing innovative techniques to satisfy modern palates.
         <br/>
         <br/>
         Barabati Sweets is not just a confectionery; it's a journey through the world of sweetness. Our team tirelessly experiments with flavors and presentation, striving to provide an unparalleled experience for our customers. Our story is shaped by the smiles on the faces of those who savor our creations.

         As we expand our offerings, we continue to stay rooted in our values of authenticity, creativity, and customer satisfaction. We invite you to be a part of our sweet narrative, where each dessert tells a story of dedication, innovation, and the pure joy of indulging in the finer things in life.
         </h5>
     </div>

     


      </div>

    </div>



<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>

<br/>
<br/>


<div >
      <h1 style={{fontSize:'50px',fontFamily:'cursive',textAlign:'center',color:'rgb(19, 19, 72)'}}>Our Vision</h1>
     </div>
     <br/>
     <br/><br/>

     <p className='t-aboutp'>
     At Barabati , our vision is to be the epitome of confectionery excellence, transcending the ordinary to create an enchanting world of sweet experiences. We aspire to be recognized as the foremost destination where innovation, tradition, and passion converge to redefine the art of dessert crafting.
     </p>
     <br/>
     <br/>
     <p className='t-aboutp'>
     In the pursuit of excellence, we see Barabati  as a brand that continually evolves, pushing the boundaries of flavor profiles and presentation. We envision our patrons indulging in a sensory journey, where each dessert is a masterpiece that reflects our dedication to craftsmanship and attention to detail
     </p>

           



    
                    {/* <h1  style={{textAlign:'center',fontFamily:'cursive',fontSize:'40px',paddingTop:'140px'}}className="t-aboutteam" >Our Team</h1>
                    <div style={{
                        padding:"19px"
                    }}></div>
                    <div className='a-Container'>
                    

                <div className='righ' >
                        <img  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIiq-Ao7dTjf2j6c8vk8kiIzk7nK2jE994tw&usqp=CAU" alt="My Image" />
                            <h3>Saurav kumar</h3>
                            <h6>Programming Analyst</h6>
                    </div>
                    <div style={{
                        padding:"10px"
                    }}></div>
                    <div className='righ'>
                        <img  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIiq-Ao7dTjf2j6c8vk8kiIzk7nK2jE994tw&usqp=CAU" alt="My Image" />
                        <h3>Saurav kumar</h3>
                            <h6>Programming Analyst</h6>
                    </div>
                    <div style={{
                        padding:"10px"
                    }}></div>
                    <div className='righ' >
                        <img  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIiq-Ao7dTjf2j6c8vk8kiIzk7nK2jE994tw&usqp=CAU" alt="My Image" />
                        <h3>Saurav kumar</h3>
                            <h6>Programming Analyst</h6>
                    </div>
                    </div> */}





     </>
  )
}

export default About