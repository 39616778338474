import React from "react";
import cateringImage from "../../assets/images/catering.png";
import "./cateringtop.css";
import Book from "./Book";

const Cateringtop = () => {
  return (
    <div className="catering-container">
      <img src={cateringImage} alt="Catering" className="catering-image" />
      <div className="catering-text">We provide catering services</div>
      <Book />
    </div>
  );
};

export default Cateringtop;
