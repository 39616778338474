import React from "react";

import "./Reservation.scss";
import "./Reservation.css"
import Image2 from "../../../assets/images/22.jpg";

const Reservation = () => {
  return (
    <div className="reservation">
      <div className="container">
        <section class="about">
        <div className="info">
          <h1>Explore Your Culinary Adventure at Barabati.</h1>
          <p>
          Barabati Sweets and Snacks is the our casual Hotel . Prepared with modern technique and flair, the restaurant distinguishes itself by focusing on rich scrumptious morsels .The Chef blends contemporary cuisine and ingredients to transform menu into the extraordinary.
          </p>
          <p>Barabati Sweets and Snacks Hotel prepares a menu of South Indian, North Indain,Chinese Food. In addition to an extensive CONTINENTA list and classic drinks, Boucherie has developed a beverage program that boasts an inspired menu of absinthe-driven signature cocktails</p>
          <button><a href="/Menu" class="read-more" style={{textDecorationLine:'none'}}>Our Menu</a></button>
        </div>
        
        <div className="r-image">
          <img src={Image2} alt="" />
        </div>
        </section>
      </div>
    </div>
  );
};

export default Reservation;
