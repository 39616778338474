// import React from "react";
// import "./Footer.scss";
// import Logo from "../../assets/images/logos/logoDefault.png";
// import Arrow from "../../assets/icons/arrow.svg";
// import facebook from "../../assets/icons/footer/facebook.svg";
// import instagram from "../../assets/icons/footer/instagram.svg";
// import twitter from "../../assets/icons/footer/twitter.svg";
// import youtube from "../../assets/icons/footer/youtube.svg";
// import { footerMenu } from "../../data";
// import './Footer.css';
// const Footer = () => {
//   return (
//     <div className="footer">
//       <div className="container">
//         <div className="logo">
//           <img src={Logo} alt="Logo" />
//         </div>
//         <div className="content">
//           <div className="newsletter">
//             <h1 >
//             DJ Foods, a leading catering company, <br/> specializes in crafting diverse and <br/> innovative menus for events. With a <br/>commitment to quality, their professional<br/> service and attention to detail create <br/>memorable dining experiences, making <br/>them a top choice for any occasion.
//               <br />
//               <br />
//               <br />
//               <br />
//             </h1>

//             <div className="social">
//               <button>
//                 <img src={facebook} alt="facebook" />
//               </button>
//               <button>
//                 <img src={instagram} alt="instagram" />
//               </button>
//               <button>
//                 <img src={twitter} alt="twitter" />
//               </button>
//               <button>
//                 <img src={youtube} alt="youtube" />
//               </button>
//             </div>
//           </div>
//           <div className="menu">
//             {footerMenu.map((item, index) => (
//               <div className="menu-item" key={index}>
//                 <h3>{item.title}</h3>

//                 <ul>
//                   {item.items.map((item, index) => (
//                     <li key={index}>{item}</li>
//                   ))}
//                 </ul>
//               </div>
//             ))}
//           </div>

//           <div className="tt-map">
//         <iframe
//           title="Google Maps"
//           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.2743617470223!2d77.54417467536283!3d13.081790487243744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae239fca36d85f%3A0x2491e7d8df223852!2sRCS%20TECHNOLOGY!5e0!3m2!1sen!2sin!4v1705736956762!5m2!1sen!2sin"

//           style={{ border: 0 }}
//           allowfullscreen=""
//           loading="lazy"
//           referrerpolicy="no-referrer-when-downgrade"
//         ></iframe>

// </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Footer;

import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import Logo from "../../assets/images/logo.png";
import facebook from "../../assets/icons/footer/facebook.svg";
import instagram from "../../assets/icons/footer/instagram.svg";
import twitter from "../../assets/icons/footer/twitter.svg";
import youtube from "../../assets/icons/footer/youtube.svg";
import "./Footer.css";
import { useEffect } from "react";
const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="logo">
          <img
            style={{ height: "100px", width: "70px" }}
            src={Logo}
            alt="Logo"
          />
        </div>
        <div className="content">
          <div className="newsletter">
            <h1>
              Barabati Sweets & Snacks,
              <br /> specializes in north Indian Foods <br />
              Aloo Partha,Paneer Butter masala and many more
              <br /> Tasy and authentic foods <br />{" "}
              unforgettable sweet experiences, making Barabati Sweets the
              perfect choice for all occasions.
              <br />
              <br />
              <br />
              <br />
            </h1>
            <div className="social">
              <button>
                <img src={facebook} alt="facebook" />
              </button>
              <button>
                <img src={instagram} alt="instagram" />
              </button>
              <button>
                <img src={twitter} alt="twitter" />
              </button>
              <button>
                <img src={youtube} alt="youtube" />
              </button>
            </div>
          </div>
          <div className="menu">
            <div className="menu-item">
              <h3>Service</h3>
              <ul>
                <li>
                  <Link style={{ color: "black" }} to="/services">
                    Events catering services
                  </Link>
                </li>
                <li>
                  <Link style={{ color: "black" }} to="/services">
                    Outdoor catering
                  </Link>
                </li>
                <li>
                  <Link style={{ color: "black" }} to="/services">
                    Corporates Catering
                  </Link>
                </li>
                <li>
                  <Link style={{ color: "black" }} to="/services">
                    Tourist Foods
                  </Link>
                </li>
                <li>
                  <Link style={{ color: "black" }} to="/services">
                    Dinein services & Take away
                  </Link>
                </li>
              </ul>
            </div>
            <div className="menu-item">
              <h3>About</h3>
              <ul>
                <li>
                  <Link style={{ color: "black" }} to="/about">
                    Our Story
                  </Link>
                </li>
                <li>
                  <Link style={{ color: "black" }} to="/services">
                    Servicess
                  </Link>
                </li>
                <li>
                  <Link style={{ color: "black" }} to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="tt-map">
            <iframe
              title="Google Maps"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15545.521358932197!2d77.5414707!3d13.0750649!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae231f59eba4d7%3A0xb39c494d928e0825!2sBarabati%20Sweets%20and%20Snacks!5e0!3m2!1sen!2sin!4v1706171816421!5m2!1sen!2sin"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
