import React from "react";
import "./catering.css";

import wedding from "../../assets/images/wedding.png";
import birthday from "../../assets/images/birthday.png";
import corporate from "../../assets/images/corporate.png";
import houseparty from "../../assets/images/houseparty.png";
import religious from "../../assets/images/religious.png";
import Cateringtop from "./Cateringtop";

const Catering = () => {
  return (
    <div className="catering-container">
      <Cateringtop />
      <div className="services-section">
        <h2>Our Services</h2>
        <div className="service-cards">
          <div className="service-card">
            <img src={wedding} alt="Wedding" />
            <h3>Wedding Catering</h3>
            <p>Exquisite catering services tailored for your special day.</p>
          </div>
          <div className="service-card">
            <img src={birthday} alt="Birthday" />
            <h3>Birthday Catering</h3>
            <p>
              Celebrate your birthday with delicious food and great service.
            </p>
          </div>

          <div className="service-card">
            <img src={corporate} alt="Corporate" />
            <h3>Corporate Events</h3>
            <p>
              Impress clients and colleagues with our professional catering
              services.
            </p>
          </div>

          <div className="service-card">
            <img src={houseparty} alt="House Party" />
            <h3>House Party Catering</h3>
            <p>Enjoy your party while we take care of the food and drinks.</p>
          </div>
          <div className="service-card">
            <img src={religious} alt="Religious" />
            <h3>Religious Gatherings</h3>
            <p>
              Share the joy of religious festivities with our catering services.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Catering;
