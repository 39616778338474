import React from 'react';
import './Services.css';
import image001 from "../../assets/images/2.jpg"
import image002 from "../../assets/images/20.jpg"
import image003 from "../../assets/images/15.jpg"
import image004 from "../../assets/images/16.jpg"
import image005 from "../../assets/images/17.jpg"
import image006 from "../../assets/images/18.jpg"

const Services = () => {
  return (
    <>
       <div class="background-container"></div>
       <div className='s-title'>
       <h1>Our Services</h1>
       </div>


       <div className='s-container'>
        <div className='s-left'>
             <div className='left1'>
                <img style={{height:'300px'}} src={image001} alt='loading' />
                <div className='left1-content'>
                <h1>Bulk Sweets And Snacks</h1>
                <p>
                    Barabati Sweets and Snacks takes pride in offering a delightful array of treats through our Bulk Sweets and Snacks Services. Whether you're hosting a grand celebration or a cozy gathering, our expertly crafted sweets and snacks in generous quantities ensure that  every  guest enjoys the perfect blend of flavors.
                    </p>
                    </div>
             </div>
             <div className='left1'>
                <img style={{height:'300px'}} src={image006} alt='loading' />
                <div className='left1-content'>
                <h1>Outdoor Catering</h1>
                <h4>
                Transform your outdoor events into culinary celebrations with Barabati's Outdoor Catering. Our expert team brings the perfect blend of flavors an dishes to seamless service, Barabati ensures a memorable dining experience under the open sky. Choose us to turn your outdoor gatherings into  moments of culinary delight and seamless hospitality.
                    </h4>
                    </div>
             </div>
             <div className='left1'>
                <img style={{height:'300px'}} src={image002} alt='loading' />
                <div className='left1-content'>
                <h1>Restaurant</h1>
                <h4>
                Our restaurant blends tradition with innovation, offering a diverse menu of mouthwatering dishes crafted with care. Immerse yourself in a cozy ambiance as our attentive staff ensures a warm dining experience. At Barabati, we invite you to savor every moment, creating memories one delicious bite at a time.
                    </h4>
                    </div>
             </div>  
             <div className='left1'>
                <img style={{height:'300px'}} src={image005} alt='loading' />
                <div className='left1-content'>
                <h1>Marriage And Birthday Parties</h1>
                <p>
                Celebrate life's special moments with Barabati's exceptional catering services for weddings and birthdays. Our expert team brings a touch of sweetness to your joyous occasions, offering a delectable selection of treats and snacks. From elegant wedding banquets to vibrant birthday parties, Barabati ensures a delightful culinary experience for you and your guests.
                    </p>
                    </div>
             </div>
             <div className='left1'>
                <img style={{height:'300px'}} src={image003} alt='loading' />
                <div className='left1-content'>
                <h1>Special Occasion</h1>
                <h4>
                Make your special occasions truly extraordinary with Barabati Sweets and Snacks. Our curated menu and impeccable service add a touch of sweetness to weddings, anniversaries, and milestone celebrations. From decadent desserts to savory delights, we cater to your unique tastes, creating memorable moments for you and your guests.
                    </h4>
                    </div>
             </div>
             <div className='left1'>
                <img style={{height:'300px'}} src={image004} alt='loading' />
                <div className='left1-content'>
                <h1>Corporate Food Services</h1>
                <h4>
                Elevate your corporate events with Barabati's distinguished Corporate Food and Services. Our culinary expertise and professional catering ensure a seamless and delightful dining experience for your business meetings, conferences, or corporate gatherings. With a commitment to excellence, Barabati offers a diverse menu crafted to impress clients and inspire productivity. 
                    </h4>
                    </div>
             </div>
        </div>
        </div>
    </>

    // New cord
    
    // End of new cord


  )
}

export default Services