const pages = new Map();
pages.set("home", { name: "Home", path: "/", anchorable: true });

pages.set("about", { name: "About", path: "/About", anchorable: true });
pages.set("menu", { name: "Menu", path: "/Menu", anchorable: true });
pages.set("services", {
  name: "Services",
  path: "/Services",
  anchorable: true,
});
pages.set("about", { name: "About", path: "/About", anchorable: true });
pages.set("contact", { name: "Contact", path: "/Contact", anchorable: true });
pages.set("IRCTC Food", {
  name: "IRCTC Food",
  path: "/Irctc",
  anchorable: true,
});
pages.set("Catering Service", {
  name: "Catering Service",
  path: "/catering",
  anchorable: true,
});

// pages.set('bookings', {
//   name: 'Reservations',
//   path: '/modal',
//   anchorable: true
// });

export default pages;
