import React from "react";
import "./book.css";

const Book = () => {
  return (
    <div className="book-container">
      <h2 className="book-heading">Book us for your event</h2>
      <p className="book-paragraph">
        Planning an event? Let us take care of the food. Our catering services
        ensure delicious meals tailored to your preferences, leaving you free to
        enjoy the occasion.
      </p>
      <button className="book-now-button">Book Now</button>
    </div>
  );
};

export default Book;
