import React, { useEffect } from "react";
import "./Contact.css";
import facebook from "../../assets/icons/footer/facebook.svg";
import instagram from "../../assets/icons/footer/instagram.svg";
import twitter from "../../assets/icons/footer/twitter.svg";
import youtube from "../../assets/icons/footer/youtube.svg";

const Contact = () => {
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="background-container"></div>

      <div className="ss-title">
        <h1>Contact us</h1>
      </div>

      <div className="c-title">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1>Get In Touch</h1>
      </div>

      <div className="c-container">
        <div className="c-contact">
          <div className="cc-container">
            <br />
            <img
              style={{ width: "80px", height: "50px" }}
              src="https://cdn-icons-png.flaticon.com/128/535/535239.png"
            />
            <br />
            <br />
            Address
            <br />
            <br />
            <p style={{ fontSize: "15px", fontFamily: "none" }}>
              Singapura Main Rd, Shriniketh Layout, Singapura, <br />
              Bengaluru, Karnataka 560014
              <br />
            </p>
          </div>
          <div className="cc-container">
            <br />
            <img
              style={{ width: "80px", height: "50px" }}
              src="https://cdn-icons-png.flaticon.com/128/542/542638.png"
            />
            <br />
            <br />
            Email
            <br />
            <br />
            <p style={{ fontSize: "17px", fontFamily: "none" }}>
              Barabati@gmail.com
            </p>
          </div>
          <div className="cc-container">
            <br />
            <img
              style={{ width: "80px", height: "50px" }}
              src="https://cdn-icons-png.flaticon.com/128/126/126509.png"
            />
            <br />
            <br />
            Phone
            <br />
            <br />
            <p style={{ fontSize: "15px", fontFamily: "none" }}>
              +91-7005072543
            </p>
          </div>
        </div>

        <br />

        <h1 style={{ color: "rgb(19, 19, 72)" }}>Send Us a Message</h1>

        <div class="form-container">
          <form action="your-google-form-link" method="post">
            <div className="c-name">
              <input
                style={{ fontSize: "20px" }}
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                required
              />

              <div style={{ padding: "15px" }}></div>

              <input
                style={{ fontSize: "20px" }}
                type="email"
                id="gmail"
                name="gmail"
                placeholder="Gmail"
                required
              />
            </div>

            <br />
            <br />

            <div className="c-message">
              <textarea
                style={{ fontSize: "20px" }}
                id="message"
                name="message"
                rows="4"
                cols="50"
                placeholder="Message"
                required
              ></textarea>
            </div>
            <br />
            <div className="c-submit">
              <button type="submit" value="Submit">
                Send
              </button>
            </div>
          </form>
        </div>
        <div className="map-container">
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15545.521358932197!2d77.5414707!3d13.0750649!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae231f59eba4d7%3A0xb39c494d928e0825!2sBarabati%20Sweets%20and%20Snacks!5e0!3m2!1sen!2sin!4v1706171816421!5m2!1sen!2sin"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            style={{
              padding: "30px",

              height: "350px",
              border: "0",
            }}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
