import "./About.scss"

import Cheff from "../../../assets/images/about/cheff.png"

import salad from "../../../assets/images/about/salad.png"
import cheese from "../../../assets/images/about/cheese.png"

import allTime from "../../../assets/images/about/icons/724.svg"
import Image2 from "../../../assets/images/21.jpg";


const About = () => {
  return (
    <div className="about">
        <div className="container">
        <div className="r-image">
          <img src={Image2} alt="" />
        </div>
            <div className="information">
                <h1>Quality food  at reasonable prices</h1>
                <p>At Barabati, we're dedicated to bringing a unique taste experience to your gatherings, ensuring each moment is accompanied by the perfect blend of sweetness and savor. </p>
                    <ul>
                        <li>
                            <img src={allTime} alt="" />
                            <span>Bulk Sweets And Snacks</span>
                        </li>
                        <li>
                            <img src={allTime} alt="" />
                            <span>Restaurant</span>
                        </li>
                        <li>
                            <img src={allTime} alt="" />
                            <span>Marriage And Birthday parties</span>
                        </li>
                        <li>
                            <img src={allTime} alt="" />
                            <span>Special Occasion</span>
                        </li>
                        <li>
                            <img src={allTime} alt="" />
                            <span>Outdoor Catering</span>
                        </li>
                        <li>
                            <img src={allTime} alt="" />
                            <span>Corporate food services</span>
                        </li>
                    </ul>
                    <button><a href="/About" style={{textDecorationLine:'none'}}>About Us</a></button>
            </div>
        </div>
    </div>
  )
}

export default About