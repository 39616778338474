import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import './Testimonial.css'

export default class Testimonials extends Component {
  render() {
    return (
      <div  className="t-container">

<div className="t-title">
          <h1 style={{textAlign:'center',paddingBottom:'10px',fontFamily:'cursive',fontSize:'50px'}}>Testimonials</h1>
        </div>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        
        
          <div>
          
          <div className="t-img">
          <img  style={{width:'180px'}} src="https://res.cloudinary.com/practicaldev/image/fetch/s--0SCWkYwS--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://dev-to-uploads.s3.amazonaws.com/i/9dhr4cw2s0skgfig8qnw.png" />
          </div>
          <br/>
          <br/>
          <div className="myCarousel">
            <h3 style={{paddingBottom:'5px'}}>Shirley Fultz</h3>
            
            <h4>Designer</h4>
            <br/>
            <p>
              It's freeing to be able to catch up on customized news and not be
              distracted by a social media element on the same site
            </p>
            <br/>
            <br/>
          </div>
        </div>

        <div>
          <img style={{width:'180px'}} src="https://res.cloudinary.com/practicaldev/image/fetch/s--0SCWkYwS--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://dev-to-uploads.s3.amazonaws.com/i/9dhr4cw2s0skgfig8qnw.png" />
          <br/>
          <br/>
          <div className="myCarousel">
            <h3 style={{paddingBottom:'5px'}}> Daniel Keystone</h3>
            <h4>Designer</h4>
            <br/>
            <p>
              The simple and intuitive design makes it easy for me use. I highly
              recommend Fetch to my peers.
            </p>
            <br/>
            <br/>
          </div>
        </div>

        <div>
          <img style={{width:'180px'}}  src="https://res.cloudinary.com/practicaldev/image/fetch/s--0SCWkYwS--/c_limit%2Cf_auto%2Cfl_progressive%2Cq_auto%2Cw_880/https://dev-to-uploads.s3.amazonaws.com/i/9dhr4cw2s0skgfig8qnw.png" />
          <br/>
          <br/>
          <div className="myCarousel">
            <h3  style={{paddingBottom:'5px'}}>Theo Sorel</h3>
            <h4>Designer</h4>
            <br/>
            <p>
              I enjoy catching up with Fetch on my laptop, or on my phone when
              I'm on the go!
            </p><br/>
            <br/>
          </div>
        </div>
        
      </Carousel>
      </div>
    );
  }
}